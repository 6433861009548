import request from '@/utils/request'
/**
 *  项目模块 api
 */
// 项目信息列表
export const subjectList = params =>
  request({
    url: '/course/list',
    method: 'get',
    params
  })

export const pay = params =>
  request({
    url: `/course/${params.id}/buy`,
    method: 'post',
    data: params
  })
export const orderList = params =>
  request({
    url: `/order/list`,
    method: 'get',
    params
  })
export const orderAccountList = params =>
  request({
    url: `/userAccount/rechargeList/options`,
    method: 'get',
    params
  })

// 获取订单详情
export const orderDetail = orderCode =>
  request({
    url: `/order/${orderCode}/describe`,
    method: 'get'
  })

export const hotCourse = params =>
  request({
    url: '/course/hot/list',
    method: 'get',
    params
  })
export const orderPay = params =>
  request({
    url: '/order/pay',
    method: 'post',
    data: params
  })

export const getCourseDetail = params =>
  request({
    url: `/course/${params.id}/describe`,
    method: 'get'
  })

export const getCommentList = params =>
  request({
    url: `/evaluation/${params.courseId}/course/list`,
    method: 'get'
  })
export const deleteComment = id =>
  request({
    url: `/evaluation/course/${id}`,
    method: 'delete'
  })

export const addComment = params =>
  request({
    url: '/evaluation/course',
    method: 'put',
    data: JSON.stringify(params)
  })

export const evaluation = params =>
  request({
    url: `/evaluation/${params.courseId}/course/check`,
    method: 'put'
  })
export const refund = orderCode =>
  request({
    url: `/order/${orderCode}/refund`,
    method: 'post'
  })

export const refundMessage = () =>
  request({
    url: `/course/refund/message`,
    method: 'get'
  })

export const passedApply = orderCode =>
  request({
    url: `/order/${orderCode}/agreeRefund`,
    method: 'post'
  })
export const rejectedApply = orderCode =>
  request({
    url: `/order/${orderCode}/outSite/refund`,
    method: 'post'
  })
