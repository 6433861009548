<template lang="pug">
.vip-container
  el-dialog(:visible.sync="centerDialogVisible"  width="800px" center @close="closeDialog")
    .dialog-title(slot="title")
      .title-left 
        .user 用户: {{ vipInfo.username }} 
          img(v-show="vipInfo.hasEffected" src="../../assets/vip.png"  draggable="false")
        .deadline {{vipInfo.hasEffected ? '续费' : '开通'}}会员有效期至: {{ formatDate(choosePrice.expirationDate) }}
      .title-right
        img(src="@/assets/vip_huangguan.png" draggable="false")
        span.tip {{vipInfo.hasEffected ? '续费' : '开通'}}会员，畅览资源
    .dialog-content 
      .category-list
        .recharge(v-for="item in  priceInfoList" :key="item.priceId" @click="changeRecharge(item)" :class="[choosePrice.priceId === item.priceId && 'choose-priceId']")
          .durationTipTop(v-show="item.tipTop") {{item.tipTop}}
          .durationUnit
            span {{ item.durationNum }} 
            span(v-show="item.durationUnit !== '年'") 个
            span {{ item.durationUnit }}
          .durationPrice  {{ `¥${item.discountPrice}` }}
          .durationPricePerMonth(v-show="item.price") {{item.price}}
      .pay-content
        .pay-type
          div(v-for="item in payTypeList" :key="item.id" @click="changePayType(item)"  :class="[choosePayType.id === item.id && 'choose-pay']")
            img(:src="item.icon" draggable="false")
            span {{ item.name }}
        .pay-qr-code(v-if="choosePayType.id !== 'gengduo'")
          img(:src="paySrc" v-if="choosePayType.id === 'weixin'")
          .pay-detail
            .pay-price {{ choosePayType.name }}扫码，支付 
              span {{ choosePrice.discountPrice }} 
              span 元
            .pay-tip 开通前请阅读并同意
              span(@click="openVipProtocol") 
              el-button(v-if="choosePayType.id === 'zhifubao'" @click="pay") 去支付
              .pay-html(v-html="payHtml")
        .pay-more(v-if="choosePayType.id === 'gengduo'")
          el-button(type="primary" round @click="$router.push({path: '/pay', query: {courseId: choosePrice.priceId, orderId: orderCode }})") 用 劵/余额 支付
          el-button(type="primary" v-if="choosePrice.hasSupportTogether || choosePrice.hasSupportBargain" round @click="$router.push({path: `/bargainPage?courseId=${choosePrice.priceId}&courseName=${choosePrice.durationUnit !== '年' ? `${choosePrice.durationNum}个${choosePrice.durationUnit}` : `${choosePrice.durationNum}${choosePrice.durationUnit}`}&&hasSupportBargain=${choosePrice.hasSupportBargain}&hasSupportTogetherBuy=${choosePrice.hasSupportTogether}&businessType=会员`})") 砍价/拼团
</template>
<script>
import {rechargeListApi, buyVip} from '@/api/vip.js'
import {formatTimeFilter} from '@/utils/index.js'
import {orderPay} from '@/api/subject'
import {checkVip} from '../../api/product'
import {mapState} from 'vuex'

export default {
  name: 'vip',
  props: {
    showVipPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      centerDialogVisible: false,
      payHtml: '',
      orderCode: '',
      vipInfo: {
        expirationDate: '',
        username: '',
        hasEffected: false
      },
      priceInfoList: [],
      payTypeList: [
        {
          name: '微信',
          icon: require('../../assets/weixin.png'),
          id: 'weixin'
        },
        {
          name: '支付宝',
          icon: require('../../assets/zhifubao.png'),
          id: 'zhifubao'
        },
        {
          name: '更多',
          icon: require('../../assets/more-pay.jpg'),
          id: 'gengduo'
        }
      ],
      choosePrice: {},
      choosePayType: {},
      paySrc: '', // 微信二维码
      payTimer: null,
      vipUserInfo: {}
    }
  },
  watch: {
    showVipPage: {
      handler(value) {
        this.centerDialogVisible = value
        if (value) {
          checkVip().then(res => {
            this.vipUserInfo = res.data
            this.checkVip()
            if (this.UserInfo && this.UserInfo.id) {
              rechargeListApi().then(res => {
                this.priceInfoList = res.data.priceInfoList
                console.log('%c [ this.priceInfoList ]-93-「index」', 'font-size:px;', this.priceInfoList)
                this.choosePrice = res.data.priceInfoList[0]
                this.choosePayType = this.payTypeList[0]
                this.pay()
                Object.assign(this.vipInfo, {
                  expirationDate: res.data.expirationDate,
                  username: res.data.username,
                  hasEffected: res.data.hasEffected
                })
              })
            }
          })
        }
      },
      immediate: false
    },
    UserInfo: {
      deep: true,
      immediate: true,
      handler() {
        //   if (this.UserInfo && this.UserInfo.id) {
        //     rechargeListApi().then(res => {
        //       this.priceInfoList = res.data.priceInfoList
        //       this.choosePrice = res.data.priceInfoList[0]
        //       this.choosePayType = this.payTypeList[0]
        //       this.pay()
        //       Object.assign(this.vipInfo, {
        //         expirationDate: res.data.expirationDate,
        //         username: res.data.username,
        //         hasEffected: res.data.hasEffected
        //       })
        //     })
        //   }
      }
    }
  },
  computed: {
    ...mapState(['UserInfo'])
  },
  beforeDestroy() {
    this.payTimer && clearInterval(this.payTimer)
    this.payTimer = null
  },
  methods: {
    formatDate(time) {
      return formatTimeFilter(time, 'YYYY-MM-DD')
    },
    checkVip() {
      if (this.UserInfo && this.UserInfo.id) {
        if (!this.payTimer) {
          this.payTimer = setInterval(() => {
            rechargeListApi().then(res => {
              if (!this.vipUserInfo.hasEffected && res.data.hasEffected) {
                this.$dialog.toast('购买成功')
                this.$emit('dialogClose', true)
                this.payTimer && clearInterval(this.payTimer)
                this.payTimer = null
                this.centerDialogVisible = false
                return
              }
              if (this.vipUserInfo.hasEffected && res.data.hasEffected) {
                const oldVipTime = new Date(this.vipUserInfo.expirationDate.replace(/-/g, '/')).getTime()
                const newVipTime = new Date(res.data.expirationDate.replace(/-/g, '/')).getTime()
                if (newVipTime > oldVipTime) {
                  this.$dialog.toast('续费成功')
                  this.$emit('dialogClose', true)
                  this.payTimer && clearInterval(this.payTimer)
                  this.payTimer = null
                  this.centerDialogVisible = false
                }
              }
            })
          }, 1000)
        }
      }
    },
    changeRecharge(type) {
      this.choosePrice = type
      if (this.choosePayType.id === 'weixin' || this.choosePayType.id === 'gengduo') {
        this.pay()
      }
    },
    changePayType(type) {
      this.choosePayType = type
      type === 'weixin' && this.pay()
    },
    pay() {
      buyVip({priceId: this.choosePrice.priceId}).then(res => {
        console.log('%c [ res.data ]-192-「index」', 'font-size:px;', res.data)
        
        this.orderCode = res.data
        if (this.changePayType === 'gengduo') {
          console.log('%c [ this.orderCode ]-194-「index」', 'font-size:px;', this.orderCode)
          return 
        }
        const params = {
          payType: this.choosePayType.id === 'weixin' ? 2 : '1',
          orderCode: res.data,
          alipayReturnUrl: window.location.href
        }
        orderPay(params).then(item => {
          // 微信支付
          if (this.choosePayType.id === 'weixin') {
            this.paySrc = item.data
            return
          } else {
            this.payHtml = item.data
            this.$nextTick(() => {
              document.punchout_form.submit()
            })
          }
        })
      })
    },
    openVipProtocol() {},
    closeDialog() {
      this.centerDialogVisible = false
      this.$emit('dialogClose')
    }
  }
}
</script>

<style lang="scss" scoped>
.vip-container {
  color: #ffffff;
  /deep/.el-dialog {
    border-radius: 10px;
  }
  /deep/.el-dialog__header {
    padding: 0;
  }
  /deep/.el-dialog__body {
    height: 733px;
    padding-top: 60px;
  }
  .dialog-title {
    padding: 28px 30px;
    height: 117px;
    background: linear-gradient(138deg, #392e10 0%, #22150a 36%, #000707 100%);
    border-radius: 10px 10px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 20px;
      height: 18px;
      object-fit: contain;
    }
    .title-left {
      text-align: left;
      font-family: PingFangSC-Medium, PingFang SC;
      .user {
        font-size: 22px;
        font-weight: 500;
      }
      .deadline {
        font-size: 18px;
        font-weight: 400;
        opacity: 0.7;
        margin-top: 8px;
      }
    }
    .title-right {
      span {
        display: inline-block;
        margin-left: 8px;
      }
    }
  }
  .dialog-content {
    .category-list {
      padding: 0 40px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 62px;
      text-align: center;
      .recharge {
        width: 150px;
        height: 228px;
        background: #fff5f5;
        padding-top: 45px;
        border-radius: 20px;
        border: 1px solid #ff724c;
        position: relative;
        cursor: pointer;
        .durationTipTop {
          width: 90px;
          height: 30px;
          line-height: 26px;
          position: absolute;
          top: -14px;
          right: -14px;
          background: linear-gradient(270deg, #ff1f00 0%, #ff7a3f 100%);
          border-radius: 0px 14px 0px 14px;
          border: 4px solid #ffffff;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
        .durationUnit {
          font-size: 28px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          margin-bottom: 12px;
        }
        .durationPrice {
          height: 50px;
          font-size: 36px;
          font-weight: 600;
          line-height: 50px;
          color: #ff5a2d;
          margin-bottom: 4px;
        }
        .durationPricePerMonth {
          height: 28px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #999999;
          line-height: 28px;
          text-decoration: line-through;
        }
      }
      .choose-priceId {
        border-color: transparent;
        background-color: transparent;
        background-image: url('../../assets/vip-charge-bg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .durationUnit {
          color: #ffffff;
        }
        .durationPrice {
          color: #ffffff;
        }
        .durationPricePerMonth {
          color: #ffffff;
        }
      }
    }
  }
  .pay-content {
    width: 720px;
    height: 316px;
    margin: 0 auto;
    background: #f4f5f5;
    box-shadow: 0px 8px 21px 1px rgba(220, 220, 220, 0.5);
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    .pay-type {
      width: 220px;
      border-radius: 20px 0 0 20px;

      div {
        padding: 28px 38px;
        font-size: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:first-child {
          border-radius: 20px 0 0 0;
        }
        img {
          width: 36px;
          height: 30px;
          object-fit: contain;
          margin-right: 10px;
        }
      }
      .choose-pay {
        background: #ffffff;
      }
    }
    .pay-more {
      background-color: #fff;
      flex: 1;
      padding: 40px 0 60px 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 20px 20px 0;
    }
    .pay-qr-code {
      background-color: #fff;
      flex: 1;
      padding: 40px 0 60px 32px;
      display: flex;
      flex-direction: column;
      border-radius: 0 20px 20px 0;
      img {
        width: 140px;
        height: 140px;
        object-fit: contain;
        margin-right: 12px;
      }
      .pay-detail {
        margin-right: 10px;
        .pay-price {
          font-size: 26px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          margin-bottom: 8px;
          span {
            &:first-child {
              color: #ff0000;
              font-size: 38px;
            }
          }
        }
        .pay-tip {
          color: #999999;
          font-size: 22px;
          span {
            color: #333333;
            cursor: pointer;
          }
          button {
            display: block;
            width: 130px;
            height: 42px;
            background: #5ad3cd;
            border-radius: 21px;
            margin-top: 24px;
            cursor: pointer;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
